import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import BackgroundImage from "gatsby-background-image";
import {convertToBgImage} from "gbimage-bridge";

const Image = (props) => {
  const { imageName, children } = props;

  const data = useStaticQuery(graphql`
    query HeroImages {
      allFile(
        filter: {
          relativePath: {}
          relativeDirectory: { eq: "hero" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              id
              gatsbyImageData(transformOptions: { fit: FILL }, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);

  return data.allFile.edges.map(({ node }) => {
    if (node.base === imageName) {
      const image = getImage(node);
      const bgImage = convertToBgImage(image);

      // return <GatsbyImage className={className} image={image} alt="image" />;

      return <BackgroundImage {...bgImage} preserveStackingContext>
        {children}
      </BackgroundImage>
    }
    return null;
  });
};

export default Image;
