import React from "react";
import styled from "styled-components";
import Image from "../../ImageQuerys/HeroImages";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { linkBlanc, linkRouge, palette } from "../../styles/styles";
import { Link } from "gatsby";

const HeroSection = (props) => {
  const { className, data, nameSpace } = props;
  const { t } = useTranslation([nameSpace]);

  return (
    <Image className="bgHero" imageName={data.imageName}>
      <section className={className}>
        <h1>{t("heroTitle")}</h1>
        <p>{t("heroDesc")}</p>
        {data.hasButtons ? (
          <div className="buttons">
            <a
              href="https://ats.lapieza.io/authorize-me"
              target="_blank"
              rel="noreferrer"
              className="first"
            >
              {t("heroFirstButton")}
            </a>
            <Link className="second" to="/contact">
              {t("heroSecondButton")}
            </Link>
          </div>
        ) : null}
      </section>
    </Image>
  );
};

export default styled(HeroSection)`
  padding: 0 16%;
  color: ${palette.whiteWhite};
  @media (max-height: 899px) {
    height: 68vh;
  }
  @media (min-height: 900px) {
    height: 68rem;
  }
  display: grid;
  grid-template-rows: repeat(3, max-content);
  grid-row-gap: 6rem;
  justify-items: center;
  align-content: center;
  @media (max-width: 600px) {
    height: fit-content;
    padding: 4rem 10%;
  }
  .bgHero {
    z-index: -1;
    position: absolute;
    max-height: 70vh;
  }
  h1 {
    font-size: 7.8rem;
    color: ${palette.whiteWhite};
    text-align: center;
  }
  p {
    font-size: 2.4rem;
    width: 75%;
    text-align: center;
    // font-family: SofiaProSemiBoldAz, sans-serif;
    line-height: 2.8rem;
  }
  .buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
    grid-column-gap: 4rem;
    @media (max-width: 600px) {
      grid-template-columns:1fr;
      grid-template-rows: repeat(2, 1fr);
      grid-row-gap: 2rem;
    }
    font-size: 1.6rem;
    font-family: SofiaProRegularAz, sans-serif;
    .first {
      ${linkRouge};
    }
    .second {
      ${linkBlanc};
    }
  }
`;
